import React from 'react';
import classNames from 'classnames';
import { TextAnimationHeading } from '../../components/text-animation-heading';
import Reveal from 'react-reveal/Reveal';
import { Link } from 'gatsby';


export const TextHeader = (props) => {
  const _classNames = classNames({
    'text-header': true,
    'text-header--content-page': !!props.contentPage
  });

  return (
    <div className={_classNames}>
      <div className="text-header__container">
        <div className="text-header__container__row">

          {props.backLink &&
            <div className="text-header__container__col">
              <Reveal effect="standard-entry">
                <Link href={props.backLink.slug} className="text-header__back-link">{props.backLinkTitle}</Link>
              </Reveal>
            </div>
          }

          <div className="text-header__container__col">
            <Reveal effect="standard-entry">
              <p className="text-header__eyebrow">{props.eyebrow}</p>
            </Reveal>
          </div>

          <div className="text-header__container__col text-header__container__col--small">
            <h1 className="text-header__title">
              <Reveal effect="react-reveal--visible">
                <TextAnimationHeading textStyle="page-title" text={props.title} />
              </Reveal>
            </h1>
          </div>

          {props.description &&
            <div className="text-header__container__col">
              <Reveal effect="standard-entry">
                <p className="text-header__sub-copy">{props.description}</p>
              </Reveal>
            </div>
          }
        </div>

      </div>
      {props.image &&
        <div className="text-header__image__container">
          <img className="text-header__image" src={props.image.url + '?w=2000&fm=jpg'} alt={props.image.alt} />
          <span className="text-header__gradient"></span>
        </div>
      }
    </div>
  )
}
