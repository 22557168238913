import React from 'react';
import classNames from 'classnames';
import { Cards } from '../cards';
import { Button } from '../../components/button';


export const Press = (props) => {
  const { releases } = props;

  const _classNames = classNames({
    'press': true
  });

  return(
    <section className={_classNames}>
      <div className="press__wrapper">
        <h2 className="press__heading press__heading--color-grey">Recent Press Releases</h2>
      </div>
      <div className="press__cards-container">
        <Cards cards={releases} colorMode={'light'} containerType={'news'} press colorMode="grey-50"/>
      </div>

      {
        /*
          Not needed for now
          <div className="press__cta">
            <Button text={"Discover"} secondary />
          </div>
        */
      }
      
    </section>
  )
}
