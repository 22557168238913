import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../layout';
import { Footer } from '../sections/footer';
import { GlobalHeader } from '../sections/global-header';
import { Press } from '../sections/press';
import { TextHeader } from '../sections/text-header';

export default class Page extends React.Component {
  render = () => {
    const releases = this.props.data.dato.allReleases;

    return (
      <Layout >
        <GlobalHeader {...this.props.pageContext.header} />
        <main>
          <TextHeader title="Press Releases" />
          <Press releases={releases} />
        </main>
        <Footer {...this.props.pageContext.footer} />
      </Layout>
    )
  }
}

export const query = graphql`
  query PressLandingQuery {
    dato {
      allReleases: allPressReleases {
        __typename
        title
        slug
        author
        image {
          url
          alt
        }
        category {
          title
          slug
        }
      }
    }
  }
`;